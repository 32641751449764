export const PMaxProperties = [
  'centralis',
  'solara',
  'southpoint',
  'alviera',
  'verge',
]
export const isPMaxProperty = (propertyName: string, submitForm?: boolean) => {
  if (typeof window !== 'undefined' && window.gtag) {
    if (propertyName && containsPmaxProperty(propertyName)) {
      // Explicitly type the keys of propertyMappings
      const propertyMappings: Record<
        'centralis' | 'solara' | 'southpoint' | 'alviera' | 'verge',
        string
      > = {
        centralis: submitForm
          ? 'AW-11267817279/byujCOu60uIZEL_-9Pwp'
          : 'AW-11267817279/EIUdCOi60uIZEL_-9Pwp',
        solara: submitForm
          ? 'AW-11267817279/mapjCKzB0-IZEL_-9Pwp'
          : 'AW-11267817279/A5HDCK_B0-IZEL_-9Pwp',
        southpoint: submitForm
          ? 'AW-11267817279/AJs8CJjF0-IZEL_-9Pwp'
          : 'AW-11267817279/e4qGCJvF0-IZEL_-9Pwp',
        alviera: submitForm
          ? 'AW-11267817279/AGiUCLCRx-IZEL_-9Pwp'
          : 'AW-11267817279/5l8HCLORx-IZEL_-9Pwp',
        verge: submitForm
          ? 'AW-11267817279/AGiUCLCRx-IZEL_-9Pwp'
          : 'AW-11267817279/jtT3CLrS0-IZEL_-9Pwp',
      }

      // Find the property in the mapping
      const matchedProperty = (
        Object.keys(propertyMappings) as Array<keyof typeof propertyMappings>
      ).find((key) => propertyName.toLowerCase().includes(key))

      if (matchedProperty) {
        const send_to = propertyMappings[matchedProperty]

        // Log the match for debugging
        console.log('propertyName:', propertyName)
        console.log('send_to:', send_to)

        // Trigger the conversion event
        window.gtag('event', 'conversion', {
          send_to,
        })
      }
    }
  }
}

export const containsPmaxProperty = (propertyName: string) => {
  return PMaxProperties.some((pmax) =>
    propertyName.toLowerCase().includes(pmax),
  )
}
